@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components{

  .btn-xl
  {
    @apply bg-[#151515] font-semibold text-[#ECC032] text-sm py-4 px-7 inline-block rounded-[5px] tracking-[-.15px]  hover:bg-[#252525] focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1 transition-all dark:focus:ring-offset-gray-800 border border-transparent; 
  }
  

  .Toastify .Toastify__toast-container .info-toast,.Toastify__toast--info,.Toastify__toast
  {
    @apply bg-[#151515] font-semibold text-center text-xs text-white  max-w-[240px] min-h-[56px] rounded-lg;
  }
  
  .btn-md
  {
    /* color: #ECC032; */
    /* text-[#073526] */
    @apply bg-[#151515] text-[#fff] font-semibold transition-all text-xs py-2 px-3 md:py-2 md:px-4 inline-block rounded-[4px] tracking-[-.15px]  hover:bg-[#454545] focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-1 transition-all dark:focus:ring-offset-gray-800 border border-transparent
  }
  .btn-md:hover
  {
    /* @apply shadow-green-100 */
  }

  .btn-md-inverted
  {
    /* color: #ECC032; */
    /* text-[#073526] */
    @apply border border-[#ECC032] font-semibold text-[#fff] transition-all text-xs py-2 px-3 md:py-2 md:px-4 inline-block rounded-[4px] tracking-[-.15px]  hover:bg-[#59d097] focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1 transition-all dark:focus:ring-offset-gray-800 border border-transparent
  }
  .btn-md-inverted:hover
  {
    /* @apply shadow-green-100 */
  }

  .btn-xs-inverted
  {
    /* color: #ECC032; */
    /* text-[#073526] */
    @apply border border-[#ECC032] font-semibold text-[#ECC032] transition-all text-xs py-2 px-3 md:py-1 md:px-3 inline-block rounded-[2px] tracking-[-.15px]  hover:bg-[#59d097] focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1 transition-all dark:focus:ring-offset-gray-800 
  }
  .btn-xs-inverted:hover
  {
    /* @apply shadow-green-100 */
  }
  .btn-xs
  {
    /* color: #ECC032; */
    /* text-[#073526] */
    @apply border border-black font-semibold text-black transition-all text-xs py-1 px-2 md:py-1 md:px-2 inline-block rounded-[2px] tracking-[-.15px]  hover:bg-black hover:text-[#ECC032] focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-1 transition-all dark:focus:ring-offset-gray-800 
  }
  .btn-xs:hover
  {
    /* @apply shadow-green-100 */
  }

  .btn-md-disabled
  {
    @apply bg-gray-50  font-medium text-gray-500 text-xs py-2 px-4 inline-block rounded-[4px] tracking-[-.15px]  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-1 transition-all dark:focus:ring-offset-gray-500 border border-gray-200
  }
  .btn-md-disabled:hover
  {

  }

  label
  {
    @apply block mb-[2px] text-[12px] font-medium text-gray-400 tracking-[-.25px]
  }

  select,.text-input
  {
    @apply w-full h-auto border border-gray-200 bg-[#fcfcfc] text-[13px] px-4 py-2 rounded-[4px] transition-all  focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-0 focus:border-gray-400 focus:outline-none focus:ring focus:ring-black focus:ring-opacity-5 tracking-[-.15px]
  }
  input
  {
    @apply transition-all  focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1
  }


  .filter-select
  {
    @apply w-full h-auto border-0 border-r  bg-[transparent] focus:bg-[transparent] active:bg-[transparent] text-[13px] px-4 py-2 rounded-[4px] transition-all  focus:outline-none focus:border-0 tracking-[-.15px] focus:ring-offset-0 focus:ring-0
  }


  #react-select-3-input
  {
    @apply focus:ring-offset-0 focus:ring-0 
  }
  
  .filter-select .filter-select__control,.css-13cymwt-control
  {
    @apply py-[5px] border-0 border-r rounded-none  border-[#e3e3e3] hover:border-[#e3e3e3]
  }

}

/* .menu-item-active::after
{
  position: absolute;
  left: 36px;
  top: 48px;
  width: 1px;
  transition: all .35s ease-in-out;
  height:calc(100% - 48px - 24px);;
  background-color: #a3a3a3;
  content: '';
} */
/* 
.menu-sub-item::after
{
  position: absolute;
  left: 36px;
  top: 50%;
  width: 12px;
  height: 1px;
  background-color: #a3a3a3;
  content: '';
} */
.menu-item-active
{
  /* background-color: #a3a3a3; */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5;
}

*
{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


/* Apply the animation to the loader element */
.loader {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Customize loader appearance */
  border-top: 4px solid #2ac08b; /* Customize loader appearance */
  border-left: 4px solid #2ac08b; /* Customize loader appearance */
  border-radius: 50%;
  width: 34px;
  height: 34px;
  animation: rotate 1s linear infinite; /* Apply the animation */
}

/* Optional: Center the loader on the screen */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table
{
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 40px;
  background-color: transparent;
  border: 1px solid #efefef;
}
thead tr
{
  background-color: #fff;
  border: 1px solid #fafafa;
}
thead tr td
{
  padding: 12px 16px;
}
thead tr td
{
  font-size: .75rem;
  font-weight: 500;
  color: #757575;
  letter-spacing: -.15px;
}

tbody tr
{
  border-bottom: 1px solid #fefefe;
}
tbody tr:hover
{
  background-color: #fafafa;
  cursor: pointer;
}
tbody tr td
{
  padding: 16px 16px;
}

tbody tr td
{
  /* font-size: .85rem;
  font-weight: 500;
  color: #151515;
  letter-spacing: -.15px; */
}

table tr td,
table tr th {
  display: flex;
  flex: 1;
  padding: 12px 32px;
  align-items:center;
  /* justify-content:center; */
}

table {
  display: flex;
  flex-direction: column;
  height: 100%;
}
table thead,
table tbody {
  display: block;
}
table thead {
  margin-right: 0px;
}
table thead tr{
  border-bottom: 1px solid #efefef;
}
table tbody {
  flex: 1;
  overflow-y: scroll;
}
table tbody tr{
  border-bottom: 1px solid #f3f3f3;
}
table tr {
  width: 100%;
  text-align: left;
  display: flex;
}
table tr td,
table tr th {
  display: flex;
  flex: 1;
  align-items:center;
  /* justify-content:center; */
}

.tab-group-active
{
}
.tab-group-active::after
{
  position: absolute;
  content: '';
  bottom: 0;
  width: 100%;
  background-color: #ECC032;
  height: 3px;
  left: 0;
}

.react-datepicker
{
  border: 0px solid #eaeaea!important;
  border-radius: 0px!important;
  width: 100%!important;
  overflow: hidden!important;
  background-color: transparent!important;
  padding: 12px 0!important;
    box-shadow: 0px 1px 24px 4px rgba(0,0,0,0)!important;
}
.react-datepicker__header
{
  background-color: transparent!important;
  border-bottom: 1px solid #f3f3f3!important;
  border-top-left-radius: 0.3rem!important;
  padding: 0!important;
  padding-bottom: 5px!important;
  color: #f3f3f3!important;
}


.react-individual-datepicker-wrapper
{
  display: block!important;
}

.react-individual-datepicker
{
  border: 1px solid #e3e3e3!important;
  border-radius: 3px!important;
  width: max-content!important;
  overflow: hidden!important;
  background-color: #fff!important;
  padding: 2px 0!important;
    box-shadow: 0px 1px 24px 4px rgba(0,0,0,0)!important;
}
.react-individual-datepicker .react-datepicker__header
{
  background-color: #f5f5f5!important;
  border-bottom: 1px solid #f3f3f3!important;
  border-top-left-radius: 0.3rem!important;
  padding: 0!important;
  padding-bottom: 0px!important;
  color: #f3f3f3!important;
}


.react-datepicker__navigation--next {
  /* background: url('../../public/right.svg') no-repeat; */
  width: 15px;
  height: 15px;
  border: none;
}

.react-datepicker__header--time
{
  display: none!Important;
}

.react-datepicker__month-container
{
  @apply w-[100%] md:w-[50%] 
  /* width: 50%!important; */
}

.react-datepicker__day
{
  color: #000!important;
  padding: 4px 10px!important;
  margin: 2px!important;
}
.react-datepicker__day--disabled
{
  color: #ccc!important;
}
.react-datepicker__day--in-range
{
  background-color: #111!important;
  color: #EDBF31!important;
}

/* .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name
{
  width: 1.4rem!important;
} */

.mob-calender .react-datepicker__day-name, .react-datepicker__day,.mob-calender .react-datepicker__time-name
{
  /* width: 1.4rem!important; */
}
.react-datepicker__current-month
{
  color: #959595!important;
  font-weight: 500!important;
  font-size: 13px!important;
  text-transform: uppercase;
  
}
.react-datepicker__day-name
{
  text-transform: uppercase;
  font-size: 11px;
  color: #959595!important;
}

.react-datepicker__time-list-item 
{
  border-radius: 2px!important;
}

.react-datepicker__time-list-item--selected
{
  background-color: #EDBF31!important;
  color: #fff!important;
}

.react-datepicker__time-box
{
  padding: 0 8px!important;
}

.react-datepicker__time-container
{
  border-left: 1px solid #efefef!important;
}

.react-datepicker__day--in-range
{
  border-radius: .15rem!important;

}

.react-datepicker__day--in-range .react-datepicker__day--selected
{
  border-radius: .05rem!important;
    background-color: #151515!important;
    color: #EDBF31!important;
    padding: 2px 8px!important;
    outline: none!important;
}
.react-datepicker__day
{
    padding: 0px 6px!important;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  @apply w-full md:w-auto
}

.track-1
{
  background-color: #EDBF31;
}
.track-2
{
  background-color: #e3e3e3;
}